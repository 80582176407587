import "./App.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useRef } from "react";
// import { logDOM } from "@testing-library/react";
// import { clear } from "@testing-library/user-event/dist/clear";
// import AlertDialog from "./dialog";

// import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

const BASE_URL = 'https://pre.api.loopx.com.cn/cabinet'

function App() {
  const ref = useRef(null);

  useEffect(() => {
    const el = document.getElementById("code");
    console.log(el);
    const el2 = ref.current;
    console.log(el2);
  }, []);

  const [user_id, setUserId] = useState(0);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  const [password, setPassword] = useState(0);

  const [loginStatus, setLoginStatus] = useState(
    localStorage.getItem("token") != null
  );
  const [doorStatus, setDoorStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  Axios.defaults.withCredentials = true;

  const submitRequest = (code) => {
    console.log(loginStatus);
    Axios.post(
      BASE_URL + '/orders/pickup',
      {
        // x_access_token: localStorage.getItem("token"),
        flag: 1,
        user_id: user_id,
        code: code,
      },
      {
        headers: { "x-access-token": localStorage.getItem("token") },
      }
    ).then((response) => {
      setMessage(response.data.message);
      setAlertStatus(true);
      setDoorStatus(true);
      console.log(localStorage.getItem("token"));
      console.log(response.data.message);
      var codeBox = document.getElementById("code");
      codeBox.value = "";
      setCode("");
    });
  };

  const login = () => {
    Axios.post(
      BASE_URL + '/login',
    {
      user_id: user_id,
      password: password,
    }).then((response) => {
      if (response.data.auth) {
        setLoginStatus(true);
        console.log("success");
        localStorage.setItem("token", response.data.token);
        console.log(response.data.token);
      } else {
        console.log("failed");
        setLoginStatus(false);
        alert("Wrong userid or password");
      }
    });
  };

  const handleClose = () => {
    setAlertStatus(false);
  };

  const input = (e) => {
    var codeBox = document.getElementById("code");
    codeBox.value = codeBox.value + e.target.value;
    console.log(codeBox.value);
    setCode(codeBox.value);
    if (codeBox.value.length === 6) {
      submitRequest(codeBox.value);
    }
  };

  const del = () => {
    var code = document.getElementById("code");
    code.value = code.value.substr(0, code.value.length - 1);
    setCode(code.value);
  };

  const clear = () => {
    var code = document.getElementById("code");
    code.value = "";
    setCode(code.value);
  };

  return (
    <div className="App">
      {!loginStatus && (
        <div class="vertical container loginpage">
          <h1>LOG IN</h1>
          <div class="horizontal">
            <div class="vertical">
              <label>Cabinet Id</label>
              <input
                type="text"
                name="user_id"
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              />
            </div>
            <div class="vertical">
              <label>Password</label>
              <input
                type="text"
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
          <button onClick={login} id="login">
            LOG IN
          </button>
        </div>
      )}

      {loginStatus && (
        <div class="vertical">
          <div>
            {" "}
            {alertStatus && (
              <div>
                <Dialog
                  open={true}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  {/* <DialogTitle id="alert-dialog-title">
                    {""}
                  </DialogTitle> */}
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Got it
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
          <div class="horizontal container header">
            <img src="LOGO1.png" id="logo" alt="LOGO" />
            <label>Tel: 2267899260</label>
          </div>
          <div class="horizontal">
            <div class="vertical container">
              <img src="car.png" class="car" alt="LOGO" />
            </div>
            <div class="vertical container">
              <h1>Input PIN:</h1>
              <input type="text" name="code" id="code" value={code} readOnly="true"/>
              <div id="VirtualNumericalKey">
                <input
                  id="btn1"
                  class="key"
                  type="button"
                  value="1"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn2"
                  class="key"
                  type="button"
                  value="2"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn3"
                  class="key"
                  type="button"
                  value="3"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <br />
                <input
                  id="btn4"
                  class="key"
                  type="button"
                  value="4"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn5"
                  class="key"
                  type="button"
                  value="5"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn6"
                  class="key"
                  type="button"
                  value="6"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <br />
                <input
                  id="btn7"
                  class="key"
                  type="button"
                  value="7"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn8"
                  class="key"
                  type="button"
                  value="8"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btn9"
                  class="key"
                  type="button"
                  value="9"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <br />
                <input
                  id="btnClear"
                  class="key"
                  type="button"
                  value="Clear"
                  onClick={clear}
                />
                <input
                  id="btn0"
                  class="key"
                  type="button"
                  value="0"
                  onClick={(e) => {
                    input(e);
                  }}
                />
                <input
                  id="btnDel"
                  class="key"
                  type="button"
                  value="Delete"
                  onClick={del}
                />
              </div>
            </div>
          </div>
          {doorStatus && <audio autoPlay src="cLupeGJN.mp3"></audio>}
        </div>
      )}
    </div>
  );
}

export default App;
